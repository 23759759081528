import React, { useState, useRef, useEffect } from 'react'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Unstable_Grid2'
import FormCover from '../FormCover'
import DOB from '../Questions/DOB'
import HavePension from '../Questions/NPH/HavePension'
import EmploymentStatus from '../Questions/NPH/EmploymentStatus'
import CurrentValue from '../Questions/NPH/CurrentValue'
import PensionTypes from '../Questions/NPH/PensionTypes'
import MostImportant from '../Questions/NPH/MostImportant'
import Borrowing from '../Questions/NPH/Borrowing'
import Name from '../Questions/Name'
import Email from '../Questions/Email'
import Phone from '../Questions/Phone'
import PrivacyPolicy from '../Questions/PrivacyPolicy'
import Invalid from '../Invalid'
import Calculating from '../Calculating'
import ThankYou from '../ThankYou'
import StartPensionAlert from '../StartPensionAlert'
import ProgressBar from 'react-bootstrap/ProgressBar'
import Loader from '../Loader'
import { calculateFormProgress } from '../../config/helpers/Helper'
import * as api from '../../config/api/GuestAPI'

const wait = t => new Promise((resolve, reject) => setTimeout(resolve, t))

export default function DataCaptureForm(props) {
    const [formVariant, setFormVariant] = useState(null)
    const [coverDismissed, setCoverDismissed] = useState(true)
    const [stage, setStage] = useState(0)
    const [loading, setLoading] = useState(false)
    const [buttonLoading, setButtonLoading] = useState(false)
    const [invalid, setInvalid] = useState()
    const [invalidReason, setInvalidReason] = useState('')
    const [redirectUrl, setRedirectUrl] = useState('')
    const [phoneError, setPhoneError] = useState(false)
    // pension details
    const [havePension, setHavePension] = useState('')
    const [employmentStatus, setEmploymentStatus] = useState('')
    const [pensionTypes, setPensionTypes] = useState([])
    const [mostImportant, setMostImportant] = useState('')
    const [currentValue, setCurrentValue] = useState('')
    const [borrowing, setBorrowing] = useState('')
    // personal details
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [dob, setDob] = useState('')
    const [email, setEmail] = useState('')
    const [phone, setPhone] = useState('')
    const [privacyPolicy, setPrivacyPolicy] = useState('')

    const wrapperRef = useRef(null)
    const currentHeight = useRef(0)
    const dcForm = useRef(null)

    // useEffect(() => {
    //     // Randomly select Form A or Form B with a 50% chance for each
    //     const randomForm = Math.random() < 0.5 ? 'cover' : 'nocover'
    //     console.log('form variant: ', randomForm)
    //     setFormVariant(randomForm)
    //     setCoverDismissed(randomForm === 'cover' ? false : true)
    // }, [])

    async function submitForm(e) {
        setLoading(true)
        if (e) {
            e.preventDefault()
        }

        const pensions = pensionTypes.length > 0 ? pensionTypes.join(',') : ''

        const details = {
            havePension,
            employmentStatus,
            pensions,
            mostImportant,
            currentValue,
            borrowing,
            firstName,
            lastName,
            dob,
            email,
            phone,
            type: 'Pension Property',
            source: 'nph'
        }
        console.log('submit details: ', details)
        
        try {
            let response = await api.submitNphPropertyForm(details)
            console.log('repsonse: ', response)

            // Add delay before thank you 
            await wait(5000)

            // Show thank you
            setLoading(false)
            setStage('thankyou')
        } catch(e) {
            console.log('Error submitting form: ', e)
        }
    }

    async function incrementStartForm() {
        try {
            api.incrementAbTotal('nphproperty')
        } catch(e) {
            console.log('Error incrementing total: ', e)
        }
    }

    function nextStage() {
        setStage(stage + 1)
    }

    function updateStage(newStage) {
        setStage(newStage)
        //dcForm.current.scrollIntoView()
    }

    function checkNextDisabled() {
        if (buttonLoading) {
            return true
        }
        else {
            if (stage === 0) {
                return !employmentStatus
            }
            if (stage === 1) {
                return !havePension
            }
            if (stage === 2) {
                return !pensionTypes.length > 0
            }
            if (stage === 3) {
                return !currentValue
            }
            if (stage === 4) {
                return !borrowing
            }
            if (stage === 5) {
                return !mostImportant
            }
            if (stage === 6) {
                return !firstName || !lastName
            }
            if (stage === 7) {
                return !dob
            }
            if (stage === 8) {
                return !email
            }
            if (stage === 9) {
                return !phone
            }
            if (stage === 10) {
                return !privacyPolicy
            }
        }
    }

    function dismissCover() {
        setCoverDismissed(true)
        incrementStartForm()
    }

    // function updateAnswer1(type) {
    //     setType(type)
    //     if (formVariant === 'nocover') {
    //         incrementStartForm()
    //     }
    // }

    function updatePensionTypes(answer) {
        //console.log('answer: ', answer)
        setPensionTypes((prevSelectedAnswers) => {
            if (prevSelectedAnswers.includes(answer)) {
                // Remove answer if it is already selected
                return prevSelectedAnswers.filter((item) => item !== answer)
            } 
            else {
                // Add answer if it is not selected
                return [...prevSelectedAnswers, answer]
            }
        })
        //console.log(pensionTypes)
    }

    return (
        <div ref={wrapperRef}>
        {loading ? (
            <Grid container justifyContent='flex-start'>
                <Grid item xs={12}>
                    <Box display='flex' justifyContent='center' alignItems='center' style={{height: '100vh'}}>
                        <Calculating 
                            redirectUrl={redirectUrl}
                            form='nph'
                            message='Submitting your details...'
                        />
                    </Box>
                </Grid>
            </Grid>
        ) : (
            <>
            {invalid &&
                <Grid container justifyContent='flex-start'>
                    <Grid item xs={12}>
                        <Box display='flex' justifyContent='center' alignItems='center' style={{height: '100vh'}}>
                            <Invalid 
                                reason={invalidReason}
                            />
                        </Box>
                    </Grid>
                </Grid>
            }
            {!coverDismissed &&
                <Grid container justifyContent='flex-start'>
                    <Grid item xs={12}>
                        <Box display='flex' justifyContent='center' alignItems='center' style={{height: '100vh'}}>
                            <FormCover 
                                site='nph'
                                title='Pension Property Assesment'
                                //subtitle=''
                                body='Can you buy a property through your pension?'
                                buttonText='Get Started'
                                dismissCover={() => dismissCover()}
                            />
                        </Box>
                    </Grid>
                </Grid>
            }
            {havePension === 'No' &&
                <Grid container justifyContent='flex-start'>
                    <Grid item xs={12}>
                        <Box display='flex' justifyContent='center' alignItems='center' style={{height: '100vh'}}>
                            <StartPensionAlert 
                                title='Sorry!'
                                subtitle='You must have an existing pension in order to buy a property with your pension.'
                                goBack={() => setHavePension('')}
                                site='nph'
                            />
                        </Box>
                    </Grid>
                </Grid>
            }
            {stage === 'thankyou' &&
                <Grid container justifyContent='flex-start'>
                    <Grid item xs={12}>
                        <Box display='flex' justifyContent='center' alignItems='center' style={{height: '100vh'}}>
                            <ThankYou 
                                site='nph'
                                name={firstName}
                                email={email}
                            />
                        </Box>
                    </Grid>
                </Grid>
            }
            {(!invalid && coverDismissed && stage !== 'thankyou' && havePension !== 'No') &&
                <form id='main-form' ref={dcForm} onSubmit={(e) => e.preventDefault()} className='nph'>
                    <Grid container justifyContent='center' className='progress-bar-container scroll-container'>
                        <Grid xs={11}>
                            <ProgressBar animated now={calculateFormProgress(stage, 10, 6)} />
                        </Grid>
                    </Grid>
                    <Grid container justifyContent='center' className='question-container scroll-container'>
                        <Grid xs={10} className='question'>
                            {stage === 0 &&
                                <EmploymentStatus 
                                    answer={employmentStatus}
                                    updateAnswer={(employmentStatus) => setEmploymentStatus(employmentStatus)}
                                    nextStage={() => nextStage()}
                                    site='nph'
                                />
                            }
                            {stage === 1 &&
                                <HavePension 
                                    answer={havePension}
                                    updateAnswer={(havePension) => setHavePension(havePension)}
                                    nextStage={() => nextStage()}
                                    site='nph'
                                />
                            }
                            {stage === 2 &&
                                <PensionTypes 
                                    answer={pensionTypes}
                                    updateAnswer={(pensionTypes) => updatePensionTypes(pensionTypes)}
                                    nextStage={() => nextStage()}
                                    site='nph'
                                />
                            }
                            {stage === 3 &&
                                <CurrentValue 
                                    answer={currentValue}
                                    updateAnswer={(currentValue) => setCurrentValue(currentValue)}
                                    nextStage={() => nextStage()}
                                    site='nph'
                                />
                            }
                            {stage === 4 &&
                                <Borrowing 
                                    answer={borrowing}
                                    updateAnswer={(borrowing) => setBorrowing(borrowing)}
                                    nextStage={() => nextStage()}
                                    site='nph'
                                />
                            }
                            {stage === 5 &&
                                <MostImportant 
                                    answer={mostImportant}
                                    updateAnswer={(mostImportant) => setMostImportant(mostImportant)}
                                    nextStage={() => nextStage()}
                                    site='nph'
                                />
                            }
                            {stage === 6 &&
                                <Name 
                                    firstName={firstName}
                                    lastName={lastName}
                                    setFirstName={(firstName) => setFirstName(firstName)}
                                    setLastName={(lastName) => setLastName(lastName)}
                                    nextStage={() => nextStage()}
                                    site='nph'
                                />
                            }
                            {stage === 7 &&
                                <DOB 
                                    answer={dob}
                                    updateAnswer={(dob) => setDob(dob)}
                                    nextStage={() => nextStage()}
                                    name={firstName}
                                    site='nph'
                                />
                            }
                            {stage === 8 &&
                                <Email 
                                    email={email}
                                    setEmail={(email) => setEmail(email)}
                                    nextStage={() => nextStage()}
                                    site='nph'
                                />
                            }
                            {stage === 9 &&
                                <Phone 
                                    phone={phone}
                                    setPhone={(phone) => setPhone(phone)}
                                    nextStage={() => nextStage()}
                                    site='nph'
                                    hasError={phoneError}
                                />
                            }
                            {stage === 10 &&
                                <PrivacyPolicy 
                                    privacyPolicy={privacyPolicy}
                                    setPrivacyPolicy={(privacyPolicy) => setPrivacyPolicy(privacyPolicy)}
                                    agree={() => submitForm()}
                                    site='nph'
                                />
                            }
                        </Grid>
                    </Grid>
                    <Grid container justifyContent='center' className='bottom-container scroll-container'>
                        <Grid xs={11} display='flex' justifyContent={stage > 0 ? 'space-between' : 'flex-end'} alignItems='center' className='buttons'>
                            {stage > 0 && 
                                <Button color='nph' variant='outlined' size='large' onClick={() => updateStage(stage - 1)}>Back</Button>
                            }
                            {stage < 10 && 
                                <Button disabled={checkNextDisabled()} color='nph' variant='contained' size='large' onClick={() => nextStage()}>
                                    {buttonLoading ? (
                                        <Loader height={10} color='white' />
                                    ) : (
                                        <>Next</>
                                    )}
                                </Button>
                            }
                            {stage === 10 &&
                                <Button color='nph' variant='contained' size='large' onClick={() => submitForm()}>I Agree</Button>
                            }
                        </Grid>
                    </Grid>
                </form>
            }
            </>
        )}
        </div>
    )
}