import React, { useState, useEffect } from 'react';
import Grid from '@mui/material/Unstable_Grid2';
import CircularProgress from '@mui/material/CircularProgress';
import { CheckCircle } from '@mui/icons-material';
import { providerOptions } from '../../config/constants/Constants';

export default function Calculating(props) {
    const [currentImage, setCurrentImage] = useState(providerOptions[0].img);
    const [counter, setCounter] = useState(0);

    useEffect(() => {
        const intervalId = setInterval(() => {
            setCounter(prevCounter => {
                const nextCounter = (prevCounter + 1) % providerOptions.length;
                setCurrentImage(providerOptions[nextCounter].img);
                return nextCounter;
            });
        }, 800);

        return () => clearInterval(intervalId);
    }, []);

    return (
        <Grid container spacing={2} justifyContent='center' alignItems='center' style={{ height: '50%' }}>
            <Grid xs={12} display='flex' justifyContent='center' alignItems='center'>
                <img src={currentImage} width='150px' alt='Provider logo' />
            </Grid>
            <Grid xs={12} display='flex' justifyContent='center' alignItems='center' style={{ textAlign: 'center' }}>
                <h3>Searching for cheaper plans...</h3>
            </Grid>
        </Grid>
    );
}