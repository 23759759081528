import React, { useState } from 'react'
import Grid from '@mui/material/Unstable_Grid2'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import Box from '@mui/material/Box'

export default function DropdownQuestion(props) {
    return (
        <Grid container alignItems='center' justifyContent='flex-start' spacing={1} sx={{ textAlign: 'left' }}>
            <Grid xs={12}>
                <h3 style={{ marginBottom: '1rem' }}>{props.question}</h3>
            </Grid>
            <Grid xs={12} sm={12}>
                <Select
                    value={props.answer}
                    placeholder='Select you tariff'
                    onChange={(e) => props.updateAnswer(e.target.value)}
                    disabled={props.disabled}
                    fullWidth
                    // autoFocus={false}
                    // disableAutoFocusItem={true}
                    // disableEnforceFocus={true}
                    // disableAutoFocus={true}
                    MenuProps={{ autoFocus: false,
                        disableAutoFocusItem: true,
                        disableEnforceFocus: true,
                        disableAutoFocus: true,
                        disableScrollLock: true,
                    }}
                    inputProps = {
                        {
                            sx: {
                                'fontSize': '1.2rem'
                            }
                        }
                    }
                >
                    {props.options.map((option, i) => {
                        return <MenuItem key={i} value={option.value}>{option.label}</MenuItem>
                    })}
                </Select>
            </Grid>
        </Grid>
    )
}