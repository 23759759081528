import React, { useState } from 'react'
import Grid from '@mui/material/Unstable_Grid2'
import Box from '@mui/material/Box'

export default function OptionsQuestion(props) {

    const options = props.options
    //console.log(options)

    function updateAnswer(answer, disabled) {
        if (!disabled) {
            props.updateAnswer(answer)
        }
    }

    function getClassName(value, disabled, img) {
        let className = 'calc-option text-only'
        if (value === props.answer) {
            className += ' active'
        }
        if (disabled) {
            className += ' disabled'
        }
        if (img) {
            className += ' image'
        }
        return className
    }

    return (
        <Grid container alignItems='center' justifyContent='flex-start' spacing={1} sx={{ textAlign: 'left' }}>
            <Grid xs={12}>
                <h3 style={{ marginBottom: '1rem' }}>{props.question}</h3>
            </Grid>
            {options.map((option, i) => {
                return (
                    <Grid xs={props.xs} sm={props.sm} key={i}>
                        <Box className={getClassName(option.value, option.disabled, option.img)} elevation={2} onClick={() => updateAnswer(option.value, option.disabled)}>
                            {option.img ? (
                                <img src={option.img} width='100px' />
                            ) : (
                                <p>{option.label}</p>
                            )}
                        </Box>
                    </Grid>
                )
            })}
        </Grid>
    )
}