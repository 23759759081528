import bordGais from '../../resources/images/comparison/providers/bord-gais.png'
import communityPower from '../../resources/images/comparison/providers/community-power.png'
import ecopowerSupply from '../../resources/images/comparison/providers/ecopower-supply.png'
import electricIreland from '../../resources/images/comparison/providers/electric-ireland.png'
import energia from '../../resources/images/comparison/providers/energia.png'
import flogas from '../../resources/images/comparison/providers/flogas.webp'
import pinergy from '../../resources/images/comparison/providers/pinergy.png'
import prepayPower from '../../resources/images/comparison/providers/prepay-power.webp'
import sseAirtricity from '../../resources/images/comparison/providers/sse-airtricity.png'
import waterpower from '../../resources/images/comparison/providers/waterpower.png'
import yunoEnergy from '../../resources/images/comparison/providers/yuno-energy.webp'

export const providerOptions = [
    {value: 'SSE Airtricity', label: 'SSE Airtricity', disabled: false, img: sseAirtricity},
    {value: 'Energia', label: 'Energia', disabled: false, img: energia},
    {value: 'Flogas', label: 'Flogas', disabled: false, img: flogas},
    {value: 'Bord Gais', label: 'Bord Gais', disabled: false, img: bordGais},
    {value: 'Pinergy', label: 'Pinergy', disabled: false, img: pinergy},
    {value: 'Yuno Energy', label: 'Yuno Energy', disabled: false, img: yunoEnergy},
    {value: 'Electric Ireland', label: 'Electric Ireland', disabled: false, img: electricIreland},
    {value: 'Community Power', label: 'Community Power', disabled: false, img: communityPower},
    {value: 'Ecopower Supply', label: 'Ecopower Supply', disabled: false, img: ecopowerSupply},
    {value: 'Prepay Power', label: 'Prepay Power', disabled: false, img: prepayPower},
    {value: 'Waterpower', label: 'Waterpower', disabled: false, img: waterpower}
]

export const eircodesToCounty = {
    "A92": "Meath",
    "Y14": "Wicklow",
    "A84": "Meath",
    "H65": "Galway",
    "N37": "Westmeath",
    "R14": "Kildare",
    "K32": "Dublin",
    "F26": "Mayo",
    "H53": "Galway",
    "P31": "Cork",
    "F31": "Mayo",
    "A75": "Monaghan",
    "A41": "Dublin",
    "F35": "Mayo",
    "F56": "Sligo",
    "P72": "Cork",
    "P75": "Cork",
    "H14": "Cavan",
    "R42": "Offaly",
    "A94": "Dublin",
    "F52": "Roscommon",
    "A98": "Wicklow",
    "V23": "Kerry",
    "E21": "Tipperary",
    "R93": "Carlow",
    "A81": "Monaghan",
    "N41": "Leitrim",
    "E32": "Tipperary",
    "P43": "Cork",
    "E25": "Tipperary",
    "F23": "Mayo",
    "F45": "Roscommon",
    "H12": "Cavan",
    "P56": "Cork",
    "F12": "Mayo",
    "H71": "Galway",
    "P85": "Cork",
    "H23": "Monaghan",
    "E91": "Tipperary",
    "P24": "Cork",
    "H16": "Cavan",
    "T12": "Cork",
    "T23": "Cork",
    "P14": "Cork",
    "P32": "Cork",
    "P47": "Cork",
    "T56": "Cork",
    "T34": "Cork",
    "R56": "Kildare",
    "A63": "Wicklow",
    "F94": "Donegal",
    "D01": "Dublin",
    "D02": "Dublin",
    "D03": "Dublin",
    "D04": "Dublin",
    "D05": "Dublin",
    "D06": "Dublin",
    "D6W": "Dublin",
    "D07": "Dublin",
    "D08": "Dublin",
    "D09": "Dublin",
    "D10": "Dublin",
    "D11": "Dublin",
    "D12": "Dublin",
    "D13": "Dublin",
    "D14": "Dublin",
    "D15": "Dublin",
    "D16": "Dublin",
    "D17": "Dublin",
    "D18": "Dublin",
    "D20": "Dublin",
    "D22": "Dublin",
    "D24": "Dublin",
    "A86": "Meath",
    "A91": "Louth",
    "X35": "Waterford",
    "A85": "Meath",
    "R45": "Meath",
    "A83": "Meath",
    "V95": "Clare",
    "Y21": "Wexford",
    "P61": "Cork",
    "H91": "Galway",
    "A42": "Dublin",
    "A96": "Dublin",
    "Y25": "Wexford",
    "R51": "Kildare",
    "R95": "Kilkenny",
    "V93": "Kerry",
    "X42": "Waterford",
    "V35": "Limerick",
    "V15": "Clare",
    "P17": "Cork",
    "F92": "Donegal",
    "F93": "Donegal",
    "V94": "Limerick",
    "V31": "Kerry",
    "T45": "Cork",
    "N39": "Longford",
    "H62": "Galway",
    "K78": "Dublin",
    "K45": "Dublin",
    "P12": "Cork",
    "K36": "Dublin",
    "P51": "Cork",
    "W23": "Kildare",
    "P25": "Cork",
    "P67": "Cork",
    "H18": "Monaghan",
    "W34": "Kildare",
    "R21": "Carlow",
    "N91": "Westmeath",
    "W91": "Kildare",
    "C15": "Meath",
    "E45": "Tipperary",
    "Y34": "Wexford",
    "W12": "Kildare",
    "V42": "Limerick",
    "A45": "Dublin",
    "R32": "Laois",
    "A67": "Wicklow",
    "F42": "Roscommon",
    "E53": "Tipperary",
    "K56": "Dublin",
    "V14": "Clare",
    "K34": "Dublin",
    "P81": "Cork",
    "F91": "Sligo",
    "K67": "Dublin",
    "E41": "Tipperary",
    "E34": "Tipperary",
    "V92": "Kerry",
    "H54": "Galway",
    "R35": "Offaly",
    "X91": "Waterford",
    "F28": "Mayo",
    "Y35": "Wexford",
    "P36": "Cork"
};

export const validEmail = new RegExp('^[a-zA-Z0-9._:$!%-]+@[a-zA-Z0-9.-]+.[a-zA-Z]$')

export const regionOptions = [
    {value: 'Urban', label: 'Urban', disabled: false},
    {value: 'Rural', label: 'Rural', disabled: false}
]

export const tariffTypeOptions = [
    {value: '24hr', label: '24hr', disabled: false},
    {value: 'Nightsaver', label: 'Nightsaver', disabled: false},
    {value: 'Smart', label: 'Smart', disabled: false}
]

export const paymentMethodOptions = [
    {value: 'Direct Debit', label: 'Direct Debit', disabled: false},
    {value: 'Pay on Receipt', label: 'Pay on Receipt', disabled: false},
    {value: 'Pay as You Go', label: 'Pay as You Go', disabled: false},
    {value: 'Credit/Debit Card', label: 'Credit/Debit Card', disabled: false},
]