import React, { useState, useRef, useEffect } from 'react'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Unstable_Grid2'
//import LifeCompareCover from '../LifeCompareCover'
import DOB from '../Questions/DOB'
import PrimaryGoal from '../Questions/NPH/PrimaryGoal'
import CurrentValue from '../Questions/NPH/CurrentValue'
import Questions from '../Questions/NPH/Questions'
import MostImportant from '../Questions/NPH/MostImportant'
import PreviousEmployers from '../Questions/NPH/PreviousEmployers'
import Name from '../Questions/Name'
import Email from '../Questions/Email'
import Phone from '../Questions/Phone'
import PrivacyPolicy from '../Questions/PrivacyPolicy'
import Invalid from '../Invalid'
import Calculating from '../Calculating'
import ThankYou from '../ThankYou'
import ProgressBar from 'react-bootstrap/ProgressBar'
import Loader from '../Loader'
import { calculateFormProgress } from '../../config/helpers/Helper'
import * as api from '../../config/api/GuestAPI'

const wait = t => new Promise((resolve, reject) => setTimeout(resolve, t))

export default function DataCaptureForm(props) {
    const [formVariant, setFormVariant] = useState(null)
    const [coverDismissed, setCoverDismissed] = useState(true)
    const [stage, setStage] = useState(0)
    const [loading, setLoading] = useState(false)
    const [buttonLoading, setButtonLoading] = useState(false)
    const [invalid, setInvalid] = useState()
    const [invalidReason, setInvalidReason] = useState('')
    const [redirectUrl, setRedirectUrl] = useState('')
    const [phoneError, setPhoneError] = useState(false)
    // pension details
    const [primaryGoal, setPrimaryGoal] = useState('')
    const [currentValue, setCurrentValue] = useState('')
    const [questions, setQuestions] = useState('')
    const [previousEmployers, setPreviousEmployers] = useState('')
    const [mostImportant, setMostImportant] = useState('')
    // personal details
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [dob, setDob] = useState('')
    const [email, setEmail] = useState('')
    const [phone, setPhone] = useState('')
    const [privacyPolicy, setPrivacyPolicy] = useState('')

    const dcForm = useRef(null)

    // useEffect(() => {
    //     // Randomly select Form A or Form B with a 50% chance for each
    //     const randomForm = Math.random() < 0.5 ? 'cover' : 'nocover'
    //     console.log('form variant: ', randomForm)
    //     setFormVariant(randomForm)
    //     setCoverDismissed(randomForm === 'cover' ? false : true)
    // }, [])

    async function submitForm(e) {
        setLoading(true)
        if (e) {
            e.preventDefault()
        }

        const details = {
            primaryGoal,
            currentValue,
            previousEmployers,
            firstName,
            lastName,
            dob,
            email,
            phone,
            type: 'Pension Transfer',
            source: 'nph'
        }
        console.log('submit details: ', details)
        
        try {
            let response = await api.submitNphTransferForm(details)
            console.log('repsonse: ', response)

            // Add delay before thank you 
            await wait(5000)

            // Show thank you
            setLoading(false)
            setStage('thankyou')
        } catch(e) {
            console.log('Error submitting form: ', e)
        }
    }

    async function incrementStartForm() {
        try {
            api.incrementAbTotal('nphtransfer')
        } catch(e) {
            console.log('Error incrementing total: ', e)
        }
    }

    function nextStage() {
        setStage(stage + 1)
    }

    function updateStage(newStage) {
        setStage(newStage)
        //dcForm.current.scrollIntoView()
    }

    function checkNextDisabled() {
        if (buttonLoading) {
            return true
        }
        else {
            if (stage === 0) {
                return !primaryGoal
            }
            if (stage === 1) {
                return !currentValue
            }
            if (stage === 2) {
                return false
            }
            if (stage === 3) {
                return !previousEmployers
            }
            if (stage === 4) {
                return !mostImportant
            }
            if (stage === 5) {
                return !firstName || !lastName
            }
            if (stage === 6) {
                return !dob
            }
            if (stage === 7) {
                return !email
            }
            if (stage === 8) {
                return !phone
            }
            if (stage === 9) {
                return !privacyPolicy
            }
        }
    }

    // function dismissCover() {
    //     setCoverDismissed(true)
    //     incrementStartForm()
    // }

    function updateAnswer1(primaryGoal) {
        setPrimaryGoal(primaryGoal)
        incrementStartForm()
    }

    return (
        <>
        {loading ? (
            <Grid container justifyContent='flex-start'>
                <Grid item xs={12}>
                    <Box display='flex' justifyContent='center' alignItems='center' style={{height: '100vh'}}>
                        <Calculating 
                            redirectUrl={redirectUrl}
                            form='nph'
                            message='Submitting your details...'
                        />
                    </Box>
                </Grid>
            </Grid>
        ) : (
            <>
            {invalid &&
                <Grid container justifyContent='flex-start'>
                    <Grid item xs={12}>
                        <Box display='flex' justifyContent='center' alignItems='center' style={{height: '100vh'}}>
                            <Invalid 
                                reason={invalidReason}
                            />
                        </Box>
                    </Grid>
                </Grid>
            }
            {!coverDismissed &&
                <Grid container justifyContent='flex-start'>
                    <Grid item xs={12}>
                        <Box display='flex' justifyContent='center' alignItems='center' style={{height: '100vh'}}>
                            NPH Cover
                        </Box>
                    </Grid>
                </Grid>
            }
            {stage === 'thankyou' &&
                <Grid container justifyContent='flex-start'>
                    <Grid item xs={12}>
                        <Box display='flex' justifyContent='center' alignItems='center' style={{height: '100vh'}}>
                            <ThankYou 
                                site='nph'
                                name={firstName}
                                email={email}
                            />
                        </Box>
                    </Grid>
                </Grid>
            }
            {(!invalid && coverDismissed && stage !== 'thankyou') &&
                <form id='main-form' ref={dcForm} onSubmit={(e) => e.preventDefault()} className='nph'>
                    <Grid container justifyContent='center' className='progress-bar-container scroll-container'>
                        <Grid xs={11}>
                            <ProgressBar animated now={calculateFormProgress(stage, 9, 5)} />
                        </Grid>
                    </Grid>
                    <Grid container justifyContent='center' className='question-container scroll-container'>
                        <Grid xs={10} className='question'>
                            {stage === 0 &&
                                <PrimaryGoal 
                                    answer={primaryGoal}
                                    updateAnswer={(primaryGoal) => updateAnswer1(primaryGoal)}
                                    nextStage={() => nextStage()}
                                    subtitle='All consultations are 100% cost free'
                                    options={[
                                        'Pensions in general',
                                        'Cashing in my pension early',
                                        'Finding the best pension fund',
                                        'Transferring my pension',
                                        'Finding old pensions',
                                        'Other'
                                    ]}
                                    site='nph'
                                />
                            }
                            {stage === 1 &&
                                <CurrentValue 
                                    answer={currentValue}
                                    updateAnswer={(currentValue) => setCurrentValue(currentValue)}
                                    nextStage={() => nextStage()}
                                    site='nph'
                                />
                            }
                            {stage === 2 &&
                                <Questions 
                                    answer={questions}
                                    updateAnswer={(questions) => setQuestions(questions)}
                                    nextStage={() => nextStage()}
                                    site='nph'
                                />
                            }
                            {stage === 3 &&
                                <PreviousEmployers 
                                    answer={previousEmployers}
                                    updateAnswer={(previousEmployers) => setPreviousEmployers(previousEmployers)}
                                    nextStage={() => nextStage()}
                                    site='nph'
                                />
                            }
                            {stage === 4 &&
                                <MostImportant 
                                    answer={mostImportant}
                                    updateAnswer={(mostImportant) => setMostImportant(mostImportant)}
                                    nextStage={() => nextStage()}
                                    site='nph'
                                />
                            }
                            {stage === 5 &&
                                <Name 
                                    firstName={firstName}
                                    lastName={lastName}
                                    setFirstName={(firstName) => setFirstName(firstName)}
                                    setLastName={(lastName) => setLastName(lastName)}
                                    nextStage={() => nextStage()}
                                    site='nph'
                                />
                            }
                            {stage === 6 &&
                                <DOB 
                                    answer={dob}
                                    updateAnswer={(dob) => setDob(dob)}
                                    nextStage={() => nextStage()}
                                    name={firstName}
                                    site='nph'
                                />
                            }
                            {stage === 7 &&
                                <Email 
                                    email={email}
                                    setEmail={(email) => setEmail(email)}
                                    nextStage={() => nextStage()}
                                    site='nph'
                                />
                            }
                            {stage === 8 &&
                                <Phone 
                                    phone={phone}
                                    setPhone={(phone) => setPhone(phone)}
                                    nextStage={() => nextStage()}
                                    site='nph'
                                    hasError={phoneError}
                                />
                            }
                            {stage === 9 &&
                                <PrivacyPolicy 
                                    privacyPolicy={privacyPolicy}
                                    setPrivacyPolicy={(privacyPolicy) => setPrivacyPolicy(privacyPolicy)}
                                    agree={() => submitForm()}
                                    site='nph'
                                />
                            }
                        </Grid>
                    </Grid>
                    <Grid container justifyContent='center' className='bottom-container scroll-container'>
                        <Grid xs={11} display='flex' justifyContent={stage > 0 ? 'space-between' : 'flex-end'} alignItems='center' className='buttons'>
                            {stage > 0 && 
                                <Button color='nph' variant='outlined' size='large' onClick={() => updateStage(stage - 1)}>Back</Button>
                            }
                            {stage < 9 && 
                                <Button disabled={checkNextDisabled()} color='nph' variant='contained' size='large' onClick={() => nextStage()}>
                                    {buttonLoading ? (
                                        <Loader height={10} color='white' />
                                    ) : (
                                        <>Next</>
                                    )}
                                </Button>
                            }
                            {stage === 9 &&
                                <Button color='nph' variant='contained' size='large' onClick={() => submitForm()}>I Agree</Button>
                            }
                        </Grid>
                    </Grid>
                </form>
            }
            </>
        )}
        </>
    )
}