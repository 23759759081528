import React, { useState } from 'react'
import Grid from '@mui/material/Unstable_Grid2'
import Box from '@mui/material/Box'
import Checkbox from '@mui/material/Checkbox'

const options = [
    'PRSA',
    'Personal Pension',
    'Occupational Pension (former employer)',
    'Occupational Pension (current employer)',
    'Executive Pension',
    'Personal Retirement Bond',
    'ARF/AMRF',
    "I'm not sure"
]

export default function PensionTypes(props) {

    function updateAnswer(e, answer) {
        e.stopPropagation()
        console.log('update answer: ', answer)
        props.updateAnswer(answer)
        //props.nextStage()
    }

    function getClassName(value) {
        let className = 'calc-option text-only'
        if (props.answer.indexOf(value) > -1) {
            className += ' active'
        }
        return className
    }

    return (
        <Grid container alignItems='center' justifyContent='flex-start' spacing={1} sx={{ textAlign: 'center' }}>
            <Grid xs={12}>
                <h2>What type of pension do you have?</h2>
                <p className='question-subtext'>Select all that apply</p>
            </Grid>
            {options.map((option, i) => {
                return (
                    <Grid xs={12} sm={6} key={i}>
                        <Box className={getClassName(option)} elevation={2} onClick={(e) => updateAnswer(e, option)}>
                            <Box display='flex' justifyContent='center' alignItems='center'>
                                <Checkbox 
                                    checked={props.answer.indexOf(option) > -1} 
                                    //onChange={(e) => updateAnswer(e, option)} 
                                    sx={{
                                        color: '#015f63',
                                        '&.Mui-checked': {
                                            color: '#015f63',
                                        },
                                        paddingTop: '0',
                                        paddingBottom: '0'
                                    }} 
                                />
                                <p>{option}</p>
                            </Box>
                        </Box>
                    </Grid>
                )
            })}
        </Grid>
    )
}