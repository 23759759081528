import React, { useState, useRef, useEffect } from 'react'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Unstable_Grid2'
import FormCover from '../FormCover'
import DOB from '../Questions/DOB'
import LifeInsuranceType from '../Questions/LifeCompare/LifeInsuranceType'
import CoverAmount from '../Questions/LifeCompare/CoverAmount'
import Smoker from '../Questions/Smoker'
import Name from '../Questions/Name'
import Email from '../Questions/Email'
import Phone from '../Questions/Phone'
import Invalid from '../Invalid'
import Calculating from '../Calculating'
import ProgressBar from 'react-bootstrap/ProgressBar'
import Loader from '../Loader'
import * as api from '../../config/api/GuestAPI'
import { calculateFormProgress, redirect } from '../../config/helpers/Helper'

const wait = t => new Promise((resolve, reject) => setTimeout(resolve, t))

export default function DataCaptureForm(props) {
    //const [formVariant, setFormVariant] = useState(null)
    const [coverDismissed, setCoverDismissed] = useState(true)
    const [stage, setStage] = useState(0)
    const [loading, setLoading] = useState(false)
    const [buttonLoading, setButtonLoading] = useState(false)
    const [invalid, setInvalid] = useState()
    const [invalidReason, setInvalidReason] = useState('')
    const [redirectUrl, setRedirectUrl] = useState('')
    const [phoneError, setPhoneError] = useState(false)
    // life insurance details
    const [type, setType] = useState('')
    const [coverAmount, setCoverAmount] = useState('')
    //const [customCoverAmount, setCustomCoverAmount] = useState('')
    // personal details
    const [dob, setDob] = useState('')
    const [smoker, setSmoker] = useState('')
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [email, setEmail] = useState('')
    const [phone, setPhone] = useState('')

    const dcForm = useRef(null)

    useEffect(() => {
        // Randomly select Form A or Form B with a 50% chance for each
        // const randomForm = Math.random() < 0.5 ? 'cover' : 'nocover'
        // console.log('form variant: ', randomForm)
        // setFormVariant(randomForm)
        // setCoverDismissed(randomForm === 'cover' ? false : true)
    }, [])

    async function submitForm(e) {
        setLoading(true)
        if (e) {
            e.preventDefault()
        }

        const details = {
            coverAmount,
            dob,
            smoker,
            firstName,
            lastName,
            email,
            phone,
            privacyPolicy: true,
            //formVariant
        }
        console.log('submit details: ', details)
        
        try {
            let ip = await api.getIp()
            let response = await api.submitLifeCompareQuotesForm(details, ip)

            // Add delay before redirect
            await wait(5000)

            if (response.isValid || firstName.toLowerCase() === 'test') {
                redirect('https://quotes.lifecompare.ie/details-submitted', response.hashedName, response.hashedEmail)
            }
            else {
                if (response.invalidReason === 'Profanity' || response.invalidReason === 'Fake Name' || response.invalidReason === 'Famous Name') {
                    //let resp = await api.sendTrollDetails(details.firstName, details.lastName, details.email, details.phone, response.invalidReason)
                    //console.log('response: ', resp)
                    redirect('https://youtu.be/GM-e46xdcUo?t=28')
                }
                else {
                    setInvalid(true)
                    if (response.invalidReason === 'Phone number prefix' || response.invalidReason === 'Phone number repeat' || response.invalidReason === 'Phone number sequential') {
                        setInvalidReason('Phone')
                    }
                    else if (response.invalidReason.includes('Duplicate in last')) {
                        setInvalidReason('Duplicate')
                    }
                    setLoading(false)
                }
            }
        } catch(error) {
            console.error('Error submitting form: ', error)
        }
    }

    async function incrementStartForm() {
        try {
            //api.incrementAbTotal('lifecompare-01')
            api.incrementAbTotal('lifecomparequotes')
        } catch(e) {
            console.log('Error incrementing total: ', e)
        }
    }

    function nextStage() {
        setStage(stage + 1)
    }

    function updateStage(newStage) {
        setStage(newStage)
        //dcForm.current.scrollIntoView()
    }

    function checkNextDisabled() {
        if (buttonLoading) {
            return true
        }
        else {
            if (stage === 0) {
                return !type
            }
            if (stage === 1) {
                return !smoker
            }
            if (stage === 2) {
                return !coverAmount
            }
            if (stage === 3) {
                return !firstName || !lastName
            }
            if (stage === 4) {
                return !dob
            }
            if (stage === 5) {
                return !email
            }
            if (stage === 6) {
                return !phone
            }
        }
    }

    function dismissCover() {
        setCoverDismissed(true)
        incrementStartForm()
    }

    function updateAnswer1(type) {
        setType(type)
        incrementStartForm()
    }

    return (
        <>
        {loading ? (
            <Grid container justifyContent='flex-start'>
                <Grid item xs={12}>
                    <Box display='flex' justifyContent='center' alignItems='center' style={{height: '100vh'}}>
                        <Calculating 
                            redirectUrl={redirectUrl}
                            form='lifecompare'
                            message='Finding the cheapest life insurance policies for you...'
                        />
                    </Box>
                </Grid>
            </Grid>
        ) : (
            <>
            {invalid ? (
                <Grid container justifyContent='flex-start'>
                    <Grid item xs={12}>
                        <Box display='flex' justifyContent='center' alignItems='center' style={{height: '100vh'}}>
                            <Invalid 
                                reason={invalidReason}
                            />
                        </Box>
                    </Grid>
                </Grid>
            ) : (
            <>
            {!coverDismissed ? (
                <Grid container justifyContent='flex-start'>
                    <Grid item xs={12}>
                        <Box display='flex' justifyContent='center' alignItems='center' style={{height: '100vh'}}>
                            <FormCover 
                                site='lifecompare'
                                title='Get Your Free Quote'
                                subtitle='Compare policies from every provider in Ireland'
                                body='Calculate the cost of your life insurance in a few clicks'
                                buttonText='Get Free Quote'
                                dismissCover={() => dismissCover()}
                            />
                        </Box>
                    </Grid>
                </Grid>
            ) : (
                <form id='main-form' ref={dcForm} onSubmit={(e) => e.preventDefault()} className='lifecompare'>
                    <Grid container justifyContent='center' className='progress-bar-container scroll-container'>
                        <Grid xs={11}>
                            <ProgressBar animated variant='info' now={calculateFormProgress(stage, 6, 3)} />
                        </Grid>
                    </Grid>
                    <Grid container justifyContent='center' className='question-container scroll-container'>
                        <Grid xs={10} className='question'>
                            {stage === 0 &&
                                <LifeInsuranceType 
                                    answer={type}
                                    updateAnswer={(type) => updateAnswer1(type)}
                                    nextStage={() => nextStage()}
                                    site='lifecompare'
                                />
                            }
                            {stage === 1 &&
                                <Smoker 
                                    answer={smoker}
                                    updateAnswer={(smoker) => setSmoker(smoker)}
                                    nextStage={() => nextStage()}
                                    site='lifecompare'
                                />
                            }
                            {stage === 2 &&
                                <CoverAmount 
                                    answer={coverAmount}
                                    updateAnswer={(coverAmount) => setCoverAmount(coverAmount)}
                                    nextStage={() => nextStage()}
                                    site='lifecompare'
                                />
                            }
                            {stage === 3 &&
                                <Name 
                                    firstName={firstName}
                                    lastName={lastName}
                                    setFirstName={(firstName) => setFirstName(firstName)}
                                    setLastName={(lastName) => setLastName(lastName)}
                                    nextStage={() => nextStage()}
                                    site='lifecompare'
                                />
                            }
                            {stage === 4 &&
                                <DOB 
                                    answer={dob}
                                    updateAnswer={(dob) => setDob(dob)}
                                    nextStage={() => nextStage()}
                                    name={firstName}
                                    site='lifecompare'
                                />
                            }
                            {stage === 5 &&
                                <Email 
                                    email={email}
                                    setEmail={(email) => setEmail(email)}
                                    nextStage={() => nextStage()}
                                    site='lifecompare'
                                />
                            }
                            {stage === 6 &&
                                <Phone 
                                    phone={phone}
                                    setPhone={(phone) => setPhone(phone)}
                                    nextStage={() => nextStage()}
                                    site='lifecompare'
                                    hasError={phoneError}
                                    phoneCallVariant={'withPhoneCall'}
                                    finalStep
                                />
                            }
                        </Grid>
                    </Grid>
                    <Grid container justifyContent='center' className='bottom-container scroll-container'>
                        <Grid xs={11} display='flex' justifyContent={stage > 0 ? 'space-between' : 'flex-end'} alignItems='center' className='buttons'>
                            {stage > 0 && 
                                <Button color='info' variant='outlined' size='large' onClick={() => updateStage(stage - 1)}>Back</Button>
                            }
                            {stage < 6 && 
                                <Button disabled={checkNextDisabled()} color='info' variant='contained' size='large' onClick={() => nextStage()}>
                                    {buttonLoading ? (
                                        <Loader height={10} color='white' />
                                    ) : (
                                        <>Next</>
                                    )}
                                </Button>
                            }
                            {stage === 6 &&
                                <Button disabled={checkNextDisabled()} color='info' variant='contained' size='large' onClick={() => submitForm()}>I Agree</Button>
                            }
                        </Grid>
                    </Grid>
                </form>
            )}
            </>
            )}
            </>
        )}
        </>
    )
}