import React, { useState, useEffect, useRef } from 'react'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Unstable_Grid2'
import CheckCircle from '@mui/icons-material/CheckCircle'
import Info from '@mui/icons-material/Info'

import logo from '../resources/images/lifecompare-logo.png'
import trust from '../resources/images/trust-logos.png'

export default function StartPensionAlert(props) {

    function goToStartPension() {
        window.parent.location.replace('https://apply.nationalpensionhelpline.ie')
    }

    function goBack() {
        props.goBack()
    }

    return (
        <Grid container alignItems='center' justifyContent='center' spacing={4} sx={{ textAlign: 'center' }}>
            <Grid xs={12}>
                {/*<img src={logo} className='lifecompare-logo' style={{width: '250px'}} />*/}
                <Info color='warning' sx={{fontSize: 60}} />
            </Grid>
            <Grid xs={12}>
                <h2>{props.title}</h2>
                <p className='question-subtext'>{props.subtitle}</p>
            </Grid>
            <Grid xs={12}>
                <Button color='nph' variant='contained' size='large' onClick={() => goToStartPension()}>Start a Pension now</Button>
            </Grid>
            <Grid xs={12}>
                <Button color='nph' variant='outlined' size='large' onClick={() => goBack()}>Go Back</Button>
            </Grid>
            {/*
            <Grid xs={12}>
                <h3>Calculate the cost of your life insurance in a few clicks</h3>
            </Grid>
            <Grid xs={12}>
                <Button color='info' variant='contained' size='large' onClick={() => props.dismissCover()}>Get Free Quote</Button>
            </Grid>
            <Grid xs={12}>
                <img src={trust} style={{width: '200px'}} />
            </Grid>
            */}
        </Grid>
    )
}