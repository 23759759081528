import React, { useState } from 'react'
import Grid from '@mui/material/Unstable_Grid2'
import CheckCircle from '@mui/icons-material/CheckCircle'
import Box from '@mui/material/Box'
import Checkbox from '@mui/material/Checkbox'
import * as api from '../config/api/GuestAPI'

export default function ThankYou(props) {
    const [subscribed, setSubscribed] = useState(false)

    function subscribeToNewsletter() {
        try {
            const data = {
                name: props.name,
                email: props.email,
                source: 'nationalpensionhelpline.ie',
                delay: 'none'
            }
            api.addNphList(data)
            setSubscribed(true)
        } catch(e) {
            console.log('Error adding lead to NPH list: ', e)
        }
    }

    return (
        <Grid container alignItems='center' justifyContent='center' spacing={4} sx={{ textAlign: 'center' }}>
            <Grid xs={12}>
                {/*<img src={logo} className='lifecompare-logo' style={{width: '250px'}} />*/}
                <CheckCircle color='success' sx={{fontSize: 60}} />
            </Grid>
            <Grid xs={12}>
                <h2>Thank You!</h2>
                <p className='question-subtext'>Your information has been submitted. A regulated financial advisor will be in touch within 24 hours.</p>
            </Grid>
            {/*
            <Grid xs={12}>
                <h3>Calculate the cost of your life insurance in a few clicks</h3>
            </Grid>
            <Grid xs={12}>
                <Button color='info' variant='contained' size='large' onClick={() => props.dismissCover()}>Get Free Quote</Button>
            </Grid>
            <Grid xs={12}>
                <img src={trust} style={{width: '200px'}} />
            </Grid>
            */}
            {(props.name && props.email) &&
                <Grid xs={12} md={10}>
                    <Box className='newsletter-sub'>
                        {subscribed ? (
                            <Box style={{marginTop: '1rem', textAlign: 'left'}}>
                                <p>Thank you for subscribing!</p>
                            </Box>
                        ) : (
                            <Box style={{cursor: 'pointer', textAlign: 'left'}} display='flex' justifyContent='flex-start' alignItems='center' onClick={() => subscribeToNewsletter()}>
                                <Checkbox 
                                    //checked={props.answer.indexOf(option) > -1} 
                                    //onChange={(e) => updateAnswer(e, option)} 
                                    sx={{
                                        color: '#015f63',
                                        '&.Mui-checked': {
                                            color: '#015f63',
                                        },
                                        paddingTop: '0',
                                        paddingBottom: '0'
                                    }} 
                                />
                                <p style={{margin: 0, padding: 0, fontSize: '20px', color: '#015f63', fontWeight: 'bold'}}>{props.name}, tick this box to join the National Pension Helpline Newsletter</p>
                            </Box>
                        )}
                        <Box style={{marginTop: '1rem', textAlign: 'left'}}>
                            <p>Stay up to date with all elements of pensions in Ireland. We cover changes to legislation, fund performance, and everything in between.</p>
                        </Box>
                    </Box>
                </Grid>
            }
        </Grid>
    )
}