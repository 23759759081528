import React, { useState } from 'react'
import Grid from '@mui/material/Unstable_Grid2'
import Box from '@mui/material/Box'
import { getPrivacyPolicy } from '../../config/helpers/Helper'

export default function PrivacyPolicy(props) {
    function getClassName(value) {
        let className = 'calc-option'
        if (value === props.privacyPolicy) {
            className += ' active'
        }
        return className
    }

    function getName(site) {
        if (site === 'energyefficiency') {
            return 'Energy Efficiency'
        }
        else if (site === 'solarfinder') {
            return 'Solar Finder'
        }
        else if (site === 'iyi') {
            return 'Insure Your Income'
        }
        else if (site === 'nph') {
            return 'National Pension Helpline'
        }
    }

    function getIndustry(site) {
        if (site === 'energyefficiency') {
            return 'a residential solar installation'
        }
        else if (site === 'solarfinder') {
            return 'a residential solar installation'
        }
        else if (site === 'iyi') {
            return 'insurance'
        }
        else if (site === 'nph') {
            return 'pensions'
        }
    }

    return (
        <Grid container alignItems='center' justifyContent='center' spacing={1} sx={{ textAlign: 'center' }}>
            <Grid xs={12}>
                {props.site === 'nph' ? (
                    <h2>I agree to be contacted by the National Pension Helpline and their agents</h2>
                ) : (
                    <h2>I agree to the {getName(props.site)} Privacy Policy</h2>
                )}
                {props.site === 'nph' ? (
                    <p className='question-subtext'>This gives permission for the NPH and our partners to contact you via phone or email in order to progress your enquiry (<a target='_blank' href={getPrivacyPolicy(props.site)}>Privacy Policy</a>)</p>
                ) : (
                    <p className='question-subtext'>Due to the complex nature of {getIndustry(props.site)}, this gives permission for {getName(props.site)} and our partners to contact you via phone or email in order to progress your enquiry (<a target='_blank' href={getPrivacyPolicy(props.site)}>Privacy Policy</a>)</p>
                )}
            </Grid>
            <Grid xs={12} sm={12}>
                <Box className={getClassName('I agree')} elevation={2} onClick={() => props.agree()}>
                    <p>I agree</p>
                </Box>
            </Grid>
        </Grid>
    )
}