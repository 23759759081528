import React, { useState, useEffect, useRef } from 'react'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Unstable_Grid2'
import Divider from '@mui/material/Divider'
import Calculating from '../Comparison/Calculating'
import InputQuestion from '../Comparison/InputQuestion'
import OptionsQuestion from '../Comparison/OptionsQuestion'
import DropdownQuestion from '../Comparison/DropdownQuestion'
import PlanSummary from '../Comparison/PlanSummary'
import * as api from '../../config/api/GuestAPI'
import { providerOptions, regionOptions, tariffTypeOptions, paymentMethodOptions,  } from '../../config/constants/Constants'
import { calculateCurrentBill, calculateAllBills, formatNumber, getTariffStartOptions } from '../../config/helpers/Helper'
import moment from 'moment'
import ResizeObserver from 'resize-observer-polyfill'
import Alert from '@mui/material/Alert'

import '../../comparison.css'

const wait = t => new Promise((resolve, reject) => setTimeout(resolve, t))

export default function DataCaptureForm(props) {
    const [stage, setStage] = useState('calculator')
    const [loading, setLoading] = useState(false)
    const [filtering, setFiltering] = useState(false)
    const [allPlans, setAllPlans] = useState([])
    const [plans, setPlans] = useState([])
    const [planPaymentMethods, setPlanPaymentMethods] = useState([])
    const [planOnlineBilling, setPlanOnlineBilling] = useState([])
    // electricity details
    const [supplier, setSupplier] = useState('')
    const [region, setRegion] = useState('Urban')
    const [tariffType, setTariffType] = useState('24hr')
    const [nightsaverUsage, setNightsaverUsage] = useState('No, use National Average')
    const [nightsaverUsageExact, setNightsaverUsageExact] = useState(38)
    const [paymentMethod, setPaymentMethod] = useState('')
    const [onlineBilling, setOnlineBilling] = useState('')
    const [planId, setPlanId] = useState('')
    const [tariffStartOptions, setTariffStartOptions] = useState([])
    const [tariffStart, setTariffStart] = useState('')
    const [customDiscount, setCustomDiscount] = useState('')
    const [electricityUsage, setElectricityUsage] = useState('No, use National Average')
    const [electricityUsageExact, setElectricityUsageExact] = useState(4200)
    const [hideRegistrationDate, setHideRegistrationDate] = useState(false)
    // results details
    const [includeSmartPlans, setIncludeSmartPlans] = useState('Yes')
    const [includeCashback, setIncludeCashback] = useState('Yes')
    //const [sendEmail, setSendEmail] = useState(false)
    //const [email, setEmail] = useState('')
    // results
    const [yourBill, setYourBill] = useState(0)
    const [exitFee, setExitFee] = useState(0)
    const [resultsWithSavings, setResultsWithSavings] = useState([])
    const [resultsWithoutSavings, setResultsWithoutSavings] = useState([])
    const [showPlansWithoutSavings, setShowPlansWithoutSaving] = useState(false)

    const wrapperRef = useRef(null)
    const resultsRef = useRef(null)
    const currentHeight = useRef(0)
    const dcForm = useRef(null)

    // Function to send the height to the parent page
    const sendHeight = () => {
        if (wrapperRef.current) {
            const newHeight = wrapperRef.current.offsetHeight // Measure height of wrapper
            if (newHeight !== currentHeight.current) {
                currentHeight.current = newHeight
                window.parent.postMessage({ type: "resizeIframe", height: newHeight }, "*")
            }
        }
    }

    const scrollToTop = () => {
        window.parent.postMessage({ type: "scrollToTop"}, "*")
    }

    useEffect(() => {
        const resizeObserver = new ResizeObserver(() => {
            sendHeight()
        });

        // Observe the wrapper element
        if (wrapperRef.current) {
            resizeObserver.observe(wrapperRef.current)
        }

        return () => {
            resizeObserver.disconnect()
        }
    }, [])

    useEffect(() => {
        // if (stage ==='results' && resultsRef.current) {
        //     console.log('scroll into view')
        //     resultsRef.current.scrollIntoView()
        // }
        if (loading === true) {
            scrollToTop()
        }
        if (stage === 'results') {
            scrollToTop()
        }
    }, [stage, loading])

    useEffect(() => {
        //sendHeight()
        getPlans()
    }, [])

    async function getPlans() {
        try {
            const plans = await api.getElectricityPlans()
            console.log('plans: ', plans.length)
            console.log('plans: ', plans)
            setAllPlans(plans)
            setPlans(plans)
        } catch(e) {
            console.log('Error getting plans: ', e)
        }
    }

    async function submitForm(e) {
        wrapperRef.current.scrollIntoView()
        //window.scrollTo(0,0)
        if (e) {
            e.preventDefault()
        }
        if (!supplier || !region || !planId || !tariffType) {
            alert('Please fill out all required fields')
            return
        }
        setLoading(true)

        let selectedPlan = planId === 'custom' ? allPlans.find(p =>
            p['Supplier'] === supplier &&
            p['Region'] === region &&
            p['Tariff Type'] === tariffType &&
            parseFloat(p['Discount'].replace('%', '')) === 0
        ) : plans.find(plan => plan['ID'] === planId)
        let planCopy = {...selectedPlan}
        if (planId === 'custom') {
            planCopy['Discount'] = customDiscount
        }
        console.log('selected plan copy: ', planCopy)

        // Calculation 1 - Current bill on selected plan
        const nightsaverPercentage = (parseFloat(nightsaverUsageExact) / 100) || 0.38 // what user entered or national average
        const electricityTotal = parseFloat(electricityUsageExact) || 4200 // what user entered or national average
        const currentBill = calculateCurrentBill(planCopy, nightsaverPercentage, electricityTotal, tariffStart, allPlans)
        console.log('estimated annual bill: €', currentBill.toFixed(2))
        
        // Check if exit fee applies - this will be added to all plans in results if it does apply
        let exitFee = 0
        const contractLength = parseInt(planCopy['Contract'], 10)
        console.log('contract length: ', contractLength)
        const contractStartDate = moment(tariffStart, 'MMMM YYYY')
        console.log('contract start: ', contractStartDate)
        const contractEndDate = contractStartDate.clone().add(contractLength, 'months')
        console.log('contract end: ', contractEndDate)
        const currentDate = moment()
        // Check if the contract is still active
        if (currentDate.isBefore(contractEndDate)) {
            exitFee = parseFloat(planCopy['Termination Fee'].replace('€', '')) || 0
            console.log('contract active - exit fee: ', exitFee)
        }

        // Calculation 2 - Bills of all other plans based on inputs - orderd from cheapest to most expensive
        // filter out plans that are not applicable

        // Filter plans based on Customer Eligibility
        const filteredPlans = allPlans.filter(plan => {
            const customerEligibility = plan['Customer Eligibility']?.toLowerCase()
            const isSameSupplier = plan['Supplier'] === planCopy['Supplier']
            const isSameRegion = plan['Region'] === region
            const isActive = plan['Show in Results'] === 'Y'

            // Exclude plans that are not active
            if (!isActive) {
                return false
            }
        
            // Exclude plans that do not match the selected region
            if (!isSameRegion) {
                return false
            }

            // Exclude plans that do not match the selected tariff type
            if (tariffType === '24hr') {
                if (plan['Tariff Type'] !== '24hr' && plan['Tariff Type'] !== 'Smart') {
                    return false
                }
            } 
            else if (tariffType === 'Nightsaver') {
                if (plan['Tariff Type'] !== 'Nightsaver' && plan['Tariff Type'] !== 'Smart') {
                    return false
                }
            } 
            else if (tariffType === 'Smart') {
                if (plan['Tariff Type'] !== 'Smart') {
                    return false
                }
            }
        
            // Logic for 'Customer Eligibility'
            if (customerEligibility?.includes('new') && customerEligibility?.includes('existing')) {
                // 'New, Existing' case: Always include
                return true
            } 
            else if (customerEligibility?.includes('new')) {
                // 'New' case: Include only if different supplier
                return !isSameSupplier
            } 
            else if (customerEligibility?.includes('existing')) {
                // 'Existing' case: Include only if same supplier
                return isSameSupplier
            }
        
            // Default: Include if no specific customer eligibility restrictions
            return true
        })
        
        console.log('Filtered plans after eligibility check:', filteredPlans)

        const allBills = calculateAllBills(filteredPlans, nightsaverPercentage, electricityTotal, includeSmartPlans, includeCashback, exitFee)
        console.log('all other plans: ', allBills)

        // Add savings to each plan and split into two lists
        const resultsWithSavings = allBills.map(plan => {
            const savings = (currentBill * 1.09 - plan['estimatedAnnualBill'] * 1.09) - exitFee
            return { ...plan, savings: savings  }
        }).filter(plan => plan.savings > 0)

        const resultsWithoutSavings = allBills.map(plan => {
            const savings = (currentBill * 1.09 - plan['estimatedAnnualBill'] * 1.09) - exitFee
            return { ...plan, savings: savings }
        }).filter(plan => plan.savings <= 0)

        await wait(5000)
        setLoading(false)
        setStage('results')
        setYourBill(currentBill)
        setExitFee(exitFee)
        setResultsWithSavings(resultsWithSavings)
        setResultsWithoutSavings(resultsWithoutSavings)
        //window.scrollTo(0,0)
    }

    function backToCalculator() {
        setStage('calculator')
    }

    function updateSupplier(supplier) {
        setSupplier(supplier)
        filterPlans(supplier, region, tariffType, paymentMethod, onlineBilling, 'supplier')
    }

    function updateRegion(region) {
        setRegion(region)
        filterPlans(supplier, region, tariffType, paymentMethod, onlineBilling, 'region')
    }

    function updateTariffType(tariffType) {
        setTariffType(tariffType)
        if (tariffType === 'Smart') {
            setIncludeSmartPlans('Yes')
        }
        filterPlans(supplier, region, tariffType, paymentMethod, onlineBilling, 'tariffType')
    }

    function updatePaymentMethod(paymentMethod) {
        setPaymentMethod(paymentMethod)
        if (paymentMethod === 'Pay as You Go') {
            setOnlineBilling('Yes')
        }
        filterPlans(supplier, region, tariffType, paymentMethod, 'Yes', 'paymentMethod')
    }

    function updateOnlineBilling(onlineBilling) {
        setOnlineBilling(onlineBilling)
        filterPlans(supplier, region, tariffType, paymentMethod, onlineBilling, 'onlineBilling')
    }

    function filterPlans(supplier, region, tariffType, paymentMethod, onlineBilling, question) {
        setFiltering(true)
        // Temporarily reset paymentMethod and onlineBilling if changing criteria could affect them
        let tempPaymentMethod = paymentMethod
        let tempOnlineBilling = onlineBilling
    
        // Reset paymentMethod and onlineBilling if the question impacts options
        if (['supplier', 'region', 'tariffType'].includes(question)) {
            tempPaymentMethod = ''
            tempOnlineBilling = ''
        }
    
        // Get available options for paymentMethod and onlineBilling based only on supplier, region, and tariffType
        const baseFilteredPlans = allPlans.filter(plan => {
            return (!supplier || plan.Supplier.toLowerCase().trim() === supplier.toLowerCase().trim()) &&
                   (!region || plan.Region.toLowerCase().trim() === region.toLowerCase().trim()) &&
                   (!tariffType || plan['Tariff Type'].toLowerCase().trim() === tariffType.toLowerCase().trim())
        })
    
        const availablePaymentMethods = [
            ...new Set(baseFilteredPlans.flatMap(plan => plan['Payment Type'].split(', ').map(type => type.trim())))
        ]
        const availableOnlineBillingOptions = [
            ...new Set(baseFilteredPlans.flatMap(plan => {
                return plan['Online Billing'].split('/').map(type => {
                    const trimmedType = type.trim()
                    return trimmedType === 'Y' ? 'Yes' : trimmedType === 'N' ? 'No' : null;
                }).filter(Boolean) // Exclude null values
            }))
        ]
    
        // Initialize onlineBilling if empty
        if (!tempOnlineBilling && availableOnlineBillingOptions.length > 0) {
            tempOnlineBilling = availableOnlineBillingOptions[0] // Default to first available option
        }
        // Initialize paymentMethod if empty 
        if (!tempPaymentMethod && availablePaymentMethods.length > 0) {
            tempPaymentMethod = availablePaymentMethods[0] // Default to first available option
        }
    
        // Map the front-end 'Yes'/'No' to back-end 'Y'/'N'
        const onlineBillingValue = tempOnlineBilling === 'Yes' ? 'Y' : tempOnlineBilling === 'No' ? 'N' : ''
    
        // Filter plans based on all selected criteria
        const filteredPlans = allPlans.filter(plan => {
            return (!supplier || plan.Supplier.toLowerCase().trim() === supplier.toLowerCase().trim()) &&
                   (!region || plan.Region.toLowerCase().trim() === region.toLowerCase().trim()) &&
                   (!tariffType || plan['Tariff Type'].toLowerCase().trim() === tariffType.toLowerCase().trim()) &&
                   (!tempPaymentMethod || plan['Payment Type'].split(',').map(type => type.trim().toLowerCase()).includes(tempPaymentMethod.toLowerCase().trim())) &&
                   (!onlineBillingValue || plan['Online Billing'].split('/').some(type => {
                       const trimmedType = type.trim()
                       return trimmedType === onlineBillingValue || trimmedType === 'Y/N'
                   }))
        })
    
        console.log('Filtered plans:', filteredPlans)
        setPlans(filteredPlans)
    
        // Update available options for paymentMethod and onlineBilling
        setPlanPaymentMethods(availablePaymentMethods)
        setPlanOnlineBilling(availableOnlineBillingOptions)
    
        // Update states
        setPaymentMethod(tempPaymentMethod)
        setOnlineBilling(tempOnlineBilling)
        setFiltering(false)
    }        

    function updatePlanId(id) {
        if (id === 'custom') {
            setPlanId('custom')
        }
        else {
            const selectedPlan = plans.find(plan => plan['ID'] === id)
            console.log('selected plan: ', selectedPlan)
            setPlanId(selectedPlan['ID'])
            let options = getTariffStartOptions(selectedPlan['Earliest Date'], selectedPlan['End Date']) || []
            console.log('options: ', options)
            setTariffStart(options.length > 0 ? options[0].value : '')
            setTariffStartOptions(options || [])
            if (selectedPlan['Contract'].toLowerCase().trim() === 'no fixed term') {
                setHideRegistrationDate(true)
            }
            else {
                setHideRegistrationDate(false)
            }
        }
    }

    return (
        <div ref={wrapperRef}>
        {loading ? (
            <Grid container justifyContent='flex-start'>
                <Grid item xs={12}>
                    <Box display='flex' justifyContent='center' alignItems='center' style={{height: '600px'}}>
                        <Calculating 
                            message='Finding plans that can save you money...'
                        />
                    </Box>
                </Grid>
            </Grid>
        ) : (
            <>
                {stage === 'calculator' &&
                    <form ref={dcForm} onSubmit={(e) => e.preventDefault()} style={{padding: '2%'}} className='comparison-form'>
                        <Grid container justifyContent='center' className='question-container'>
                            <Grid xs={10} style={{marginBottom: '2rem'}}>
                                <Divider className='calc-section-divider'><h2>Your Electricity</h2></Divider>
                            </Grid>
                            <Grid xs={10} className='question'>
                                <OptionsQuestion
                                    question='Select your current electricity supplier...'
                                    options={providerOptions}
                                    answer={supplier}
                                    updateAnswer={(supplier) => updateSupplier(supplier)}
                                    site='energyefficiency'
                                    xs={6}
                                    sm={4}
                                />
                            </Grid>
                            {supplier &&
                                <>
                                    <Grid xs={10} className='question'>
                                        <OptionsQuestion
                                            question='What is your Electricity supply region?'
                                            options={regionOptions}
                                            answer={region}
                                            updateAnswer={(region) => updateRegion(region)}
                                            site='energyefficiency'
                                            xs={12}
                                            sm={6}
                                        />
                                    </Grid>
                                    <Grid xs={10} className='question'>
                                        <OptionsQuestion
                                            question='What type of electricity tariff do you currently have?'
                                            options={tariffTypeOptions}
                                            answer={tariffType}
                                            updateAnswer={(tariffType) => updateTariffType(tariffType)}
                                            site='energyefficiency'
                                            xs={12}
                                            sm={4}
                                        />
                                    </Grid>
                                    {tariffType === 'Nightsaver' &&
                                    <>
                                        <Grid xs={10} className='question'>
                                            <OptionsQuestion
                                                question='Do you know what percentage of electricity you use during Nightsaver hours?'
                                                options={
                                                    [
                                                        {value: 'Yes', label: 'Yes', disabled: false},
                                                        {value: 'No, use National Average', label: 'No, use National Average', disabled: false}
                                                    ]
                                                }
                                                answer={nightsaverUsage}
                                                updateAnswer={(nightsaverUsage) => setNightsaverUsage(nightsaverUsage)}
                                                site='energyefficiency'
                                                xs={12}
                                                sm={6}
                                            />
                                        </Grid>
                                        {nightsaverUsage === 'Yes' &&
                                            <Grid xs={10} className='question'>
                                                <InputQuestion
                                                    question='What percentage of your electricity is during Nightsaver hours?'
                                                    type='number'
                                                    placeholder='38 (National Average)'
                                                    answer={nightsaverUsageExact}
                                                    updateAnswer={(nightsaverUsageExact) => setNightsaverUsageExact(nightsaverUsageExact)}
                                                    site='energyefficiency'
                                                />
                                            </Grid>
                                        }
                                    </>
                                    }
                                    <Grid xs={10} className='question'>
                                        <OptionsQuestion
                                            question='How do you pay your electricity bills?'
                                            options={paymentMethodOptions.map(option => ({
                                                ...option,
                                                disabled: planPaymentMethods.length > 0 ? !planPaymentMethods.includes(option.value) : false
                                            }))}
                                            answer={paymentMethod}
                                            updateAnswer={(paymentMethod) => updatePaymentMethod(paymentMethod)}
                                            site='energyefficiency'
                                            xs={12}
                                            sm={3}
                                        />
                                    </Grid>
                                    {paymentMethod !== 'Pay as You Go' &&
                                        <Grid xs={10} className='question'>
                                            <OptionsQuestion
                                                question='Do you use online billing?'
                                                options={
                                                    [
                                                        {value: 'Yes', label: 'Yes', disabled: false},
                                                        {value: 'No', label: 'No', disabled: false}
                                                    ].map(option => ({
                                                        ...option,
                                                        disabled: planOnlineBilling.length > 0 ? !planOnlineBilling.includes(option.value) : false
                                                    }))
                                                }
                                                answer={onlineBilling}
                                                updateAnswer={(onlineBilling) => updateOnlineBilling(onlineBilling)}
                                                site='energyefficiency'
                                                xs={12}
                                                sm={6}
                                            />
                                        </Grid>
                                    }
                                    <Grid xs={10} className='question'>
                                        <DropdownQuestion
                                            question='What is your tariff called?'
                                            options={[{ label: 'Enter discount manually', value: 'custom' }, ...plans.map((plan, index) => ({
                                                label: plan['Tariff Name'],
                                                value: plan['ID']
                                            }))]}
                                            plans={plans}
                                            answer={planId}
                                            updateAnswer={(id) => updatePlanId(id)}
                                            disabled={filtering}
                                            site='energyefficiency'
                                        />
                                    </Grid>
                                    {planId === 'custom' &&
                                        <Grid xs={10} className='question'>
                                            <InputQuestion
                                                question='Please enter your current discount'
                                                type='number'
                                                placeholder='20'
                                                answer={customDiscount}
                                                updateAnswer={(customDiscount) => setCustomDiscount(customDiscount)}
                                                site='energyefficiency'
                                            />
                                        </Grid>
                                    }
                                    {!hideRegistrationDate &&
                                        <Grid xs={10} className='question'>
                                            <DropdownQuestion
                                                question='When did you sign up to this tariff?'
                                                // options={tariffStartOptions.map((option) => {
                                                //     return {value: option, label: option}
                                                // })}
                                                options={tariffStartOptions}
                                                answer={tariffStart}
                                                updateAnswer={(tariffStart) => setTariffStart(tariffStart)}
                                                site='energyefficiency'
                                            />
                                        </Grid>
                                    }
                                    <Grid xs={10} className='question'>
                                        <OptionsQuestion
                                            question='Do you know how much electricity you use?'
                                            options={
                                                [
                                                    {value: 'Yes', label: 'Yes', disabled: false},
                                                    {value: 'No, use National Average', label: 'No, use National Average', disabled: false}
                                                ]
                                            }
                                            answer={electricityUsage}
                                            updateAnswer={(electricityUsage) => setElectricityUsage(electricityUsage)}
                                            site='energyefficiency'
                                            xs={12}
                                            sm={6}
                                        />
                                    </Grid>
                                    {electricityUsage === 'Yes' &&
                                        <Grid xs={10} className='question'>
                                            <InputQuestion
                                                question='How much electricity do you user per year? (kWh)'
                                                type='number'
                                                placeholder='4,200 (National Average)'
                                                answer={electricityUsageExact}
                                                updateAnswer={(electricityUsageExact) => setElectricityUsageExact(electricityUsageExact)}
                                                site='energyefficiency'
                                            />
                                        </Grid>
                                    }
                                </>
                            }
                        </Grid>
                        {supplier &&
                            <Grid container justifyContent='center' >
                                <Grid xs={10} style={{marginBottom: '2rem'}}>
                                    <Divider className='calc-section-divider'><h2>Your Results</h2></Divider>
                                </Grid>
                                {tariffType !== 'Smart' &&
                                    <Grid xs={10} className='question'>
                                        <OptionsQuestion
                                            question='Include smart plans in results?'
                                            options={
                                                [
                                                    {value: 'Yes', label: 'Yes', disabled: false},
                                                    {value: 'No', label: 'No', disabled: false}
                                                ]
                                            }
                                            answer={includeSmartPlans}
                                            updateAnswer={(includeSmartPlans) => setIncludeSmartPlans(includeSmartPlans)}
                                            site='energyefficiency'
                                            xs={12}
                                            sm={6}
                                        />
                                    </Grid>
                                }
                                <Grid xs={10} className='question'>
                                    <OptionsQuestion
                                        question='Include cashback in calculations?'
                                        options={
                                            [
                                                {value: 'Yes', label: 'Yes', disabled: false},
                                                {value: 'No', label: 'No', disabled: false}
                                            ]
                                        }
                                        answer={includeCashback}
                                        updateAnswer={(includeCashback) => setIncludeCashback(includeCashback)}
                                        site='energyefficiency'
                                        xs={12}
                                        sm={6}
                                    />
                                </Grid>
                            </Grid>
                        }
                        {supplier &&
                            <Grid container justifyContent='center' alignItems='center'>
                                <Grid xs={11} display='flex' justifyContent={'center'} alignItems='center' className='buttons'>
                                    <Button variant='contained' style={{width: 300, height: 60, fontSize: '1.1rem'}} onClick={() => submitForm()}>Find Cheaper Plans</Button>
                                </Grid>
                            </Grid>
                        }
                    </form>
                }
                {stage === 'results' &&
                    <div>
                        <Grid container justifyContent='center' alignItems='center' ref={resultsRef}>
                            <Grid xs={10} display='flex' justifyContent='center' alignItems='center' className='buttons' style={{textAlign: 'center', marginTop: '1rem', marginBottom: '1rem'}}>
                                <Button variant='contained' size='small' onClick={() => backToCalculator()}>Change Plan Details</Button>
                            </Grid>
                        </Grid>
                        <Grid container justifyContent='center' alignItems='center'>
                            <Grid item xs={10} style={{textAlign: 'center', marginTop: '1rem', marginBottom: '1rem'}}>
                                {resultsWithSavings.length > 0 ? (
                                    <h2>Good news! You can save <span className='savings'>€{formatNumber(resultsWithSavings[0]['savings'])}</span> by switching your electricity</h2>
                                ) : (
                                    <h2>Unfortunately we could not find any plans that you can save by switching to. Please ensure you have entered your details correctly.</h2>
                                )}
                            </Grid>
                            <Grid item xs={10} style={{textAlign: 'center', marginBottom: '1rem'}}>
                                <p>We calculate that your current electricity costs for the next 12 months are €{formatNumber(yourBill * 1.09)} (Inc. VAT at 9.0%)</p>
                            </Grid>
                            {exitFee > 0 &&
                                <Grid item xs={10} style={{textAlign: 'center', marginBottom: '1rem'}}>
                                    <Alert severity='warning'>Note: If you switch now you may be subject to an exit fee of €{formatNumber(exitFee)}. This exit fee is included in your savings calculations.</Alert>
                                </Grid>
                            }
                        </Grid>
                        {resultsWithSavings.length > 0 && 
                            <Grid container justifyContent='center' alignItems='center' spacing={2}>
                                <Grid item xs={10}>
                                    <p>Showing {resultsWithSavings.length} plans with savings</p>
                                </Grid>
                                {resultsWithSavings.map((plan) => {
                                    return (
                                        <Grid item xs={10} key={plan['ID']}>
                                            <PlanSummary
                                                plan={plan}
                                                yourBill={yourBill}
                                                includeCashback={includeCashback}
                                            />
                                        </Grid>
                                    )
                                })}
                            </Grid>
                        }
                        {resultsWithoutSavings.length > 0 && 
                            <Grid container justifyContent='center' alignItems='center' spacing={2}>
                                <Grid item xs={10}>
                                    {showPlansWithoutSavings ? (
                                        <p>Showing {resultsWithoutSavings.length} plans without savings</p>
                                    ) : (
                                        <p className='show-plans-text' onClick={() => setShowPlansWithoutSaving(true)}>Show {resultsWithoutSavings.length} more plans without savings</p>
                                    )}
                                </Grid>
                                {showPlansWithoutSavings && resultsWithoutSavings.map((plan) => {
                                    return (
                                        <Grid item xs={10} key={plan['ID']}>
                                            <PlanSummary
                                                plan={plan}
                                                yourBill={yourBill}
                                                includeCashback={includeCashback}
                                            />
                                        </Grid>
                                    )
                                })}
                            </Grid>
                        }
                    </div>
                }
            </>
        )}
        </div>
    )
}