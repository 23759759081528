import moment from 'moment'

export function getColor(site) {
    if (site === 'energyefficiency') {
        return 'success'
    }
    else if (site === 'solarfinder') {
        return 'warning'
    }
    else if (site === 'iyi') {
        return 'warning'
    }
    else if (site === 'lifecompare') {
        return 'info'
    }
    else if (site === 'nph') {
        return 'nph'
    }
    else {
        return 'success'
    }
}

export function getPrivacyPolicy(site) {
    if (site === 'energyefficiency') {
        return 'https://energyefficiency.ie/privacy-policy'
    }
    else if (site === 'solarfinder') {
        return 'https://solarfinder.ie/privacy-policy'
    }
    else if (site === 'iyi') {
        return 'https://insureyourincome.ie/privacy-policy'
    }
    else if (site === 'lifecompare') {
        return 'https://lifecompare.ie/privacy-policy'
    }
    else if (site === 'nph') {
        return 'https://nationalpensionhelpline.ie/privacy-policy'
    }
}

export function getLast12Months() {
    const months = []
    const currentDate = new Date()

    for (let i = 0; i < 12; i++) {
        // Create a new date object adjusted for each month
        const date = new Date(currentDate.getFullYear(), currentDate.getMonth() - i, 1)
        // Format the date as "Month Year"
        const monthYear = date.toLocaleString('default', { month: 'long', year: 'numeric' })
        months.push(monthYear)
    }

    // Reverse the array to have the most recent month first
    return months.reverse()
}

function getStandardPlan(plan, allPlans) {
    const standardPlan = allPlans.find(p => {
        // Normalize and sanitize comparison values
        const supplierMatches = p['Supplier']?.trim().toLowerCase() === plan['Supplier']?.trim().toLowerCase()
        const regionMatches = p['Region']?.trim().toLowerCase() === plan['Region']?.trim().toLowerCase()
        const tariffTypeMatches = p['Tariff Type']?.trim().toLowerCase() === plan['Tariff Type']?.trim().toLowerCase()
        const isStandardPlan = p['Standard Plan']?.trim().toLowerCase() === 'y'

        // should online billing & payment method be added here?
    
        return supplierMatches && regionMatches && tariffTypeMatches && isStandardPlan
    })

    if (!standardPlan) {
        console.error('Standard plan not found')
        return 0
    }

    return standardPlan
}

// Rates - what is used is determined by tariffType for both current and standard plans
function getRates(plan) {
    //console.log('get rates: ', plan)
    return {
        discount: parseFloat(plan['Discount'].replace('%', '')) / 100 || 0,
        dayRate: parseFloat(plan['Day Rate (Exc VAT)']) / 100 || 0,
        nightRate: parseFloat(plan['Night Rate (Exc VAT)']) / 100 || 0,
        peakRate: parseFloat(plan['Peak Rate (Exc VAT)']) / 100 || 0,
        evRate: parseFloat(plan['EV Rate (Exc VAT)']) / 100 || 0,
        psoLevy: parseFloat(plan['PSO Levy (Exc VAT)'].replace('€', '')) || 0,
        standingCharge: parseFloat(plan['Standing Charge (Exc VAT)'].replace('€', '')) || 0,
        prepaymentServiceCharge: parseFloat(plan['Prepayment Service Charge (Exc VAT)'].replace('€', '')) || 0
    }
}

// Helper function to calculate the cost based on plan details
function calculateUsageCost(plan, rates, nightsaverPercentage, electricityUsage, includeCharges) {
    let usageCost = 0

    if (plan['Tariff Type'] === '24hr') {
        const discountedRate = rates.dayRate * (1 - rates.discount)
        usageCost = electricityUsage * discountedRate
        //console.log('discounted rate: ', discountedRate)
    } 
    else if (plan['Tariff Type'] === 'Nightsaver') {
        const discountedDayRate = rates.dayRate * (1 - rates.discount)
        const discountedNightRate = rates.nightRate * (1 - rates.discount)
        const dayUsage = electricityUsage * (1 - nightsaverPercentage)
        const nightUsage = electricityUsage * nightsaverPercentage
        const dayCost = dayUsage * discountedDayRate
        const nightCost = nightUsage * discountedNightRate
        usageCost = dayCost + nightCost
        // console.log('day rate: ', discountedDayRate)
        // console.log('night rate: ', discountedNightRate)
        // console.log('day usage: ', dayUsage)
        // console.log('night usage: ', nightUsage)
        // console.log('day cost: ', dayCost)
        // console.log('night cost: ', nightCost)
        // console.log('usage cost: ', usageCost)
    } 
    else if (plan['Tariff Type'] === 'Smart') {
        const discountedDayRate = rates.dayRate * (1 - rates.discount)
        const discountedNightRate = rates.nightRate * (1 - rates.discount)
        const discountedPeakRate = rates.peakRate * (1 - rates.discount)
        const discountedEvRate = rates.evRate * (1 - rates.discount)

        if (rates.dayRate && !rates.nightRate && !rates.peakRate && !rates.evRate) {
            usageCost = electricityUsage * discountedDayRate
        } 
        else if (rates.dayRate && rates.nightRate && !rates.peakRate && !rates.evRate) {
            const dayCost = electricityUsage * 0.62 * discountedDayRate
            const nightCost = electricityUsage * 0.38 * discountedNightRate
            usageCost = dayCost + nightCost
        } 
        else if (rates.dayRate && rates.nightRate && rates.peakRate && !rates.evRate) {
            // Differenciate between urban/rural for Day/Night/Peak rates
            const percentages = {
                'urban': {
                    day: 0.54,
                    night: 0.37,
                    peak: 0.09
                },
                'rural': {
                    day: 0.55,
                    night: 0.34,
                    peak: 0.11
                }
            }
            const dayCost = electricityUsage * percentages[plan['Region'].trim().toLowerCase()].day * discountedDayRate
            console.log('day cost: ', dayCost)
            const peakCost = electricityUsage * percentages[plan['Region'].trim().toLowerCase()].peak * discountedPeakRate
            console.log('peak cost: ', peakCost)
            const nightCost = electricityUsage * percentages[plan['Region'].trim().toLowerCase()].night * discountedNightRate
            console.log('night cost: ', nightCost)
            usageCost = dayCost + peakCost + nightCost
        } 
        else if (rates.dayRate && rates.nightRate && rates.peakRate && rates.evRate) {
            const percentages = {
                'Electric Ireland': {
                    'urban': {
                        day: 0.535,
                        night: 0.29,
                        peak: 0.095,
                        ev: 0.08
                    },
                    'rural': {
                        day: 0.55,
                        night: 0.27,
                        peak: 0.11,
                        ev: 0.07
                    }
                },
                'SSE Airtricity': {
                    'urban': {
                        day: 0.53,
                        night: 0.25,
                        peak: 0.09,
                        ev: 0.13
                    },
                    'rural': {
                        day: 0.545,
                        night: 0.245,
                        peak: 0.11,
                        ev: 0.10
                    }
                },
                'Bord Gais': {
                    'urban': {
                        day: 0.55,
                        night: 0.24,
                        peak: 0.08,
                        ev: 0.13
                    },
                    'rural': {
                        day: 0.565,
                        night: 0.24,
                        peak: 0.095,
                        ev: 0.10
                    }
                },
                'Energia': {
                    'urban': {
                        day: 0.84,
                        night: 0,
                        peak: 0,
                        ev: 0.16
                    },
                    'rural': {
                        day: 0.87,
                        night: 0,
                        peak: 0,
                        ev: 0.13
                    }
                },
                'Pinergy': {
                    'urban': {
                        day: 0.87,
                        night: 0,
                        peak: 0,
                        ev: 0.13
                    },
                    'rural': {
                        day: 0.90,
                        night: 0,
                        peak: 0,
                        ev: 0.10
                    }
                },
                'fallback': {
                    day: 0.53,
                    night: 0.24,
                    peak: 0.09,
                    ev: 0.14
                }
            }
        
            const region = plan['Region']?.trim().toLowerCase()
            const supplier = plan['Supplier']
            const supplierPercentages = percentages[supplier]?.[region] || percentages['fallback']
            console.log('Using percentages:', supplierPercentages)
        
            const dayCost = electricityUsage * (supplierPercentages.day) * discountedDayRate
            console.log('day cost: ', dayCost)
        
            const peakCost = electricityUsage * (supplierPercentages.peak) * discountedPeakRate
            console.log('peak cost: ', peakCost)
        
            const nightCost = electricityUsage * (supplierPercentages.night) * discountedNightRate
            console.log('night cost: ', nightCost)
        
            const evCost = electricityUsage * (supplierPercentages.ev) * discountedEvRate
            console.log('ev cost: ', evCost)
        
            usageCost = dayCost + peakCost + nightCost + evCost
        }        
    }

    // Exceptions 

    // Pinergy 'Family Time'
    // dayRate used as normal and peak for 5 hours
    if (plan['Supplier'] === 'Pinergy' && plan['Tariff Name'] === "Pinergy Lifestyle 'Family Time'") {
        console.log('EXCEPTION - Pinergy family time')
        const normalRateCost = electricityUsage * (19/24) * rates.dayRate
        console.log('normal rate cost (19 hours): ', normalRateCost)
        const specialRateCost = electricityUsage * (5/24) * rates.peakRate
        console.log('special rate cost (5 hours): ', specialRateCost)
        usageCost = normalRateCost + specialRateCost
    }
    // Pinergy 'Working from Home'
    // peakRate used as normal and day for 8 hours - should be switched for consistency
    if (plan['Supplier'] === 'Pinergy' && plan['Tariff Name'] === "Pinergy Lifestyle 'Working From Home Time'") {
        console.log('EXCEPTION - Pinergy working from home time')
        const normalRateCost = electricityUsage * (16/24) * rates.dayRate
        console.log('normal rate cost (16 hours): ', normalRateCost)
        const specialRateCost = electricityUsage * (8/24) * rates.peakRate
        console.log('special rate cost (8 hours): ', specialRateCost)
        usageCost = normalRateCost + specialRateCost
    }
    // Pinergy 'EV Drive Time'
    // if (plan['Supplier'] === 'Pinergy' && plan['Tariff Name'] === "Pinergy Lifestyle 'EV Drive Time'") {
    //     console.log('EXCEPTION - Pinergy EV drive time')
    //     const normalRateCost = electricityUsage * (21/24) * rates.dayRate
    //     console.log('normal rate cost (21 hours): ', normalRateCost)
    //     const specialRateCost = electricityUsage * (3/24) * rates.evRate
    //     console.log('special rate cost (3 hours): ', specialRateCost)
    //     usageCost = normalRateCost + specialRateCost
    // }
    // Pinergy 'Smart 7 to 10 24hr'
    if (plan['Supplier'] === 'Pinergy' && plan['Tariff Name'].includes('Pinergy Smart')) {
        console.log('EXCEPTION - Pinergy Smart 7 to 10 24hr & 7 to 10 nightsaver')
        const normalRateCost = electricityUsage * 0.88 * rates.dayRate
        console.log('normal rate cost: ', normalRateCost)
        const specialRateCost = electricityUsage * 0.12 * rates.peakRate
        console.log('special rate cost: ', specialRateCost)
        usageCost = normalRateCost + specialRateCost
    }
    // SSE Airtricity weekend plans
    if (plan['Supplier'] === 'SSE Airtricity' && plan['Tariff Name'].includes('Smart Weekends')) {
        console.log('EXCEPTION - SSE Smart Weekends')
        const discountedDayRate = rates.dayRate * (1 - rates.discount)
        const discountedNightRate = rates.nightRate * (1 - rates.discount)
        const discountedPeakRate = rates.peakRate * (1 - rates.discount)
        // percentages used are taken from doc - should they be calculated here instead? If so, how?
        const weekDayCost = electricityUsage * 0.39 * discountedDayRate
        const weekPeakCost = electricityUsage * 0.08 * discountedPeakRate
        const weekNightCost = electricityUsage * 0.29 * discountedNightRate
        const weekendDayCost = electricityUsage * 0.16 * (discountedDayRate / 2)
        const weekendPeakCost = electricityUsage * 0.03 * (discountedPeakRate / 2)
        const weekendNightCost = electricityUsage * 0.05 * (discountedNightRate / 2)
        usageCost = weekDayCost + weekPeakCost + weekNightCost + weekendDayCost + weekendPeakCost + weekendNightCost
    } 
    // Bord Gais weekend plans
    if (plan['Supplier'] === 'Bord Gais' && (plan['Tariff Name'].includes('Smart Weekend Electricity') || plan['Tariff Name'].includes('Weekend Smart Electricity'))) {
        console.log('EXCEPTION - Bord Gais Weekend Electricity')
        const discountedDayRate = rates.dayRate * (1 - rates.discount)
        const discountedNightRate = rates.nightRate * (1 - rates.discount)
        const discountedPeakRate = rates.peakRate * (1 - rates.discount)
        // percentages used are taken from doc - should they be calculated here instead? If so, how?
        const dayCost = electricityUsage * 0.39 * discountedDayRate
        const peakCost = electricityUsage * 0.07 * discountedPeakRate
        const nightCost = electricityUsage * 0.23 * discountedNightRate
        const weekendCost = electricityUsage * 0.31 * discountedNightRate
        usageCost = dayCost + peakCost + nightCost + weekendCost
    }
    // Bord Gais single day free rates
    if (plan['Supplier'] === 'Bord Gais' && (plan['Tariff Name'].includes('Free Time Saturday') || plan['Tariff Name'].includes('Free Time Sunday') || plan['Tariff Name'].includes('Green Free Saturday') || plan['Tariff Name'].includes('Green Free Sunday'))) {
        console.log('EXCEPTION - Bord Gais Single Day Free Electricity')
        const discountedDayRate = rates.dayRate * (1 - rates.discount)
        const discountedNightRate = rates.nightRate * (1 - rates.discount)
        const discountedPeakRate = rates.peakRate * (1 - rates.discount)
        // percentages used are taken from doc - should they be calculated here instead? If so, how?
        const dayCost = electricityUsage * 0.5165 * discountedDayRate
        const peakCost = electricityUsage * 0.0683 * discountedPeakRate
        const nightCost = electricityUsage * 0.3702 * discountedNightRate
        const zeroCost = electricityUsage * 0.045 * 0
        usageCost = dayCost + peakCost + nightCost + zeroCost
    }
    // Electric Ireland Weekender
    if (plan['Supplier'] === 'Electric Ireland' && plan['Tariff Name'] === 'Home Electric+ Weekender') {
        console.log('EXCEPTION - Electric Ireland Weekender')
        const discountedDayRate = rates.dayRate * (1 - rates.discount)
        // percentages used are taken from doc - should they be calculated here instead? If so, how?
        const dayCost = electricityUsage * 0.9155 * discountedDayRate
        const zeroCost = electricityUsage * 0.0845 * 0
        usageCost = dayCost + zeroCost
    }


    // Add PSO Levy and Standing Charge to the usage cost if set to true
    if (includeCharges) {
        usageCost += (rates.psoLevy + rates.standingCharge + rates.prepaymentServiceCharge)
    }
    return usageCost
}

export function calculateCurrentBill(plan, nightsaverPercentage, electricityTotal, tariffStart, allPlans) {
    // all calculations done excluding VAT
    console.log('nightsaver %: ', nightsaverPercentage)
    console.log('electricity total: ', electricityTotal)
    console.log('tariff start: ', tariffStart)

    // Calculate months on current plan
    const startDate = !tariffStart || tariffStart === 'Over 12 months ago'
    ? moment().subtract(365, 'days') // Default to 12 months ago
    : moment(tariffStart, 'D MMMM YYYY')
    const currentDate = moment()

    // const monthsOnCurrentPlan = 12 - currentDate.diff(startDate, 'months')
    // const monthsOnStandardPlan = 12 - monthsOnCurrentPlan
    // console.log('months on current plan: ', monthsOnCurrentPlan)
    // console.log('months on standard plan: ', monthsOnStandardPlan)

    const daysOnCurrentPlan = 365 - currentDate.diff(startDate, 'days')
    const daysOnStandardPlan = 365 - daysOnCurrentPlan
    console.log('days on current plan: ', daysOnCurrentPlan)
    console.log('days on standard plan: ', daysOnStandardPlan)

    const currentPlanUsage = (daysOnCurrentPlan / 365) * electricityTotal
    const standardPlanUsage = (daysOnStandardPlan / 365) * electricityTotal
    console.log('current plan usage: ', currentPlanUsage)
    console.log('standard plan usage: ', standardPlanUsage)

    // Find the corresponding 'Standard plan' version of the current plan
    const standardPlan = getStandardPlan(plan, allPlans)
    console.log('standard plan: ', standardPlan)

    // rates - what is used is determined by tariffType
    const currentPlanRates = getRates(plan)
    console.log('current plan rates: ', currentPlanRates)
    const standardPlanRates = getRates(standardPlan)
    console.log('standard plan rates: ', currentPlanRates)

    let totalBill = 0

    // Calculate bill for current plan usage - include charges
    totalBill += calculateUsageCost(plan, currentPlanRates, nightsaverPercentage, currentPlanUsage, true)
    console.log('total bill current: ', totalBill)

    // Calculate bill for standard plan usage - exclude charges as included above
    totalBill += calculateUsageCost(standardPlan, standardPlanRates, nightsaverPercentage, standardPlanUsage, false)
    console.log('total bill standard: ', totalBill)

    return totalBill
}

export function calculateAllBills(allPlans, nightsaverPercentage, electricityTotal, includeSmartPlans, includeCashback) {
    const updatedPlans = allPlans.map(plan => {
        const rates = getRates(plan)

        // Skip smart plans if includeSmartPlans is false
        if (includeSmartPlans === 'No' && plan['Tariff Type'] === 'Smart') {
            return null
        }

        // Calculate usage cost
        const totalAnnualBill = calculateUsageCost(plan, rates, nightsaverPercentage, electricityTotal, true)

        // Add PSO Levy and Standing Charge
        //const totalAnnualBill = usageCost + rates.psoLevy + rates.standingCharge

        // Include cashback if applicable
        if (includeCashback === 'Yes' && plan['Cash Bonus'] && plan['Cash Bonus'] !== '€0') {
            const cashback = parseFloat(plan['Cash Bonus'].replace('€', '')) || 0
            return { 
                ...plan, 
                estimatedAnnualBill: totalAnnualBill - cashback
            }
        }

        return { 
            ...plan, 
            estimatedAnnualBill: totalAnnualBill
        }
    }).filter(plan => plan !== null) // Remove null values for plans not included

    // Sort by estimated annual bill (cheapest first)
    return updatedPlans.sort((a, b) => a.estimatedAnnualBill - b.estimatedAnnualBill)
}

export function calculateFormProgress(currentStep, totalSteps, firstYSteps) {
    if (currentStep > totalSteps) {
        // Cap at 100% if the user somehow exceeds the last step
        return 100
    }

    const startProgress = 10
    const endProgressFirstY = 90

    if (currentStep === 0) {
        // Start progress at 10%
        return startProgress
    }

    if (currentStep <= firstYSteps) {
        // Calculate progress for the first Y steps, evenly distributed from 10% to 90%
        const progressPerStep = (endProgressFirstY - startProgress) / firstYSteps
        return startProgress + progressPerStep * currentStep
    } 
    else {
        const remainingSteps = totalSteps - firstYSteps
        const progressAfterFirstY = endProgressFirstY
        const endProgress = 100

        // Calculate how much progress for each step after the first Y steps
        const progressPerRemainingStep = (endProgress - progressAfterFirstY) / remainingSteps

        // Make sure we reach 100% on the last step (currentStep === totalSteps)
        return progressAfterFirstY + (currentStep - firstYSteps) * progressPerRemainingStep
    }
}

export function getAge(dateString) {
    var today = new Date();
    var parts = dateString.split('/');
    // Assuming dateString is in "DD/MM/YYYY"
    var birthDate = new Date(parts[2], parts[1] - 1, parts[0]);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
    }
    return age;
}

export function formatNumber(number) {
    const formattedValue = new Intl.NumberFormat('en-US', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    }).format(number)

    return formattedValue
}

export function solarRedirect(url, provider, hashedName, hashedEmail) {
    const urlWithParams = `${url}?p=${provider}&n=${hashedName}&e=${hashedEmail}`
    window.parent.location.replace(urlWithParams)
}

export function redirect(url, hashedName, hashedEmail) {
    const urlWithParams = `${url}?n=${hashedName}&e=${hashedEmail}`
    window.parent.location.replace(urlWithParams)
}

export function getTariffStartOptions(earliestDate, endDate) {
    let options = []
  
    // Handle 'Earliest Date' special cases
    if (earliestDate === 'N/A') {
        return null // Hide the question completely
    }

    if (earliestDate === 'Over 12 Months Ago') {
        options.push({label: 'Over 12 months ago', value: 'Over 12 months ago'})
        // Add the last 12 months to the options
        let current = moment().subtract(11, 'months').startOf('month') // Start 11 months ago
        for (let i = 0; i < 12; i++) {
            options.push({
                label: current.format('MMMM YYYY'), 
                value: current.format('D MMMM YYYY')
            })
            current.add(1, 'months')
        }
        return options
    }

    // Parse earliest date if it's a valid month-year format
    let parsedEarliestDate = moment(earliestDate, 'D MMMM YYYY', true)
    if (!parsedEarliestDate.isValid()) {
        console.warn('Invalid Earliest Date:', earliestDate)
        return null // Hide the question completely if parsing fails
    }

    // Parse end date if available
    let parsedEndDate
    if (endDate === 'Now' || endDate === 'N/A') {
        parsedEndDate = moment() // Use current date if 'Now' or 'N/A'
    } 
    else {
        parsedEndDate = moment(endDate, 'D MMMM YYYY', true)
        if (!parsedEndDate.isValid()) {
            console.warn('Invalid End Date:', endDate)
            parsedEndDate = moment() // Default to current date if parsing fails
        }
    }
    console.log('Parsed Earliest Date:', parsedEarliestDate.format('D MMMM YYYY'))
    console.log('Parsed End Date:', parsedEndDate.format('D MMMM YYYY'))

    // Ensure the date range is valid (earliestDate should not be after endDate)
    if (parsedEarliestDate.isAfter(parsedEndDate)) {
        console.warn('Earliest date is after end date. Returning empty options.')
        return []
    }

    // Determine the date range for options
    let current = moment(parsedEarliestDate)
    while (current.isSameOrBefore(parsedEndDate)) {
        options.push({
            label: current.format('MMMM YYYY'), 
            value: current.format('D MMMM YYYY')
        })
        // after first month default to start of month
        current.add(1, 'months').startOf('month')
    }

    console.log('Generated Options:', options)
    return options
}
